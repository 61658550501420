<template>
    <div>
        <v-simple-table>
            <thead>
                <tr>
                    <th>Dimensions</th>
                    <th>Playback Duration</th>
                    <th>Transition Duration</th>
                </tr>
            </thead>
            <tbody>
                <tr class="text-left">
                    <td>
                        <span>{{ metadata.width }} x {{ metadata.height }}</span>
                        <span class="space caption">px</span>
                    </td>
                    <td>
                        <span>{{ metadata.playback_duration }}</span>
                        <span class="space caption">s</span>
                    </td>
                    <td>
                        <span>{{ metadata.transition_duration }}</span>
                        <span class="space caption">ms</span>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>

<script>
export default {
    name: "PackagedWidgetMetadata",
    props: {
        metadata: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>
.space {
    padding-left: 1px;
}
</style>